html {
  --primary-font: "Lato", sans-serif;
  --primary-color: #066860;
  --secondary-color: #ffa34f;
  --grey-color: #8f8f8f;
  --error-or-danger-color: #a80000;
}

body {
  font-family: "Lato", sans-serif;
}

.error-message-field-generic,
.error-message-field-generic p {
  color: #ff0033;
  font-size: 15px;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-35 {
  font-size: 35px !important;
}

.login-header-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 1rem 0 1rem;
}
.landing-subscription-header-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1rem 0 1rem;
}

.social-media-buttons img:first-child {
  margin-right: 1rem;
}

.login-screen .a-side {
  padding: 0.5rem 1.5rem;
}

.pateint-profile .patient-parent-class {
  border: 1px solid rgba(179, 179, 179, 1) !important;
  border-radius: 12px;
  padding: 1rem;
}

.pateint-profile .patient-parent-class .table > tbody > tr > td {
  line-height: 40px;
  text-align: left !important;
}

.pateint-profile .patient-parent-class .table-sm > thead > tr > th {
  text-align: left !important;
}

.form-floating > label {
  text-transform: capitalize;
}

.next-button.next-button-icon span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pateint-profile-modal .upload-signature-section {
  border-radius: 10px;
  border: 1px solid #bfc9d4;
  padding: 1.5rem;
}

.c-stepper__item.tab_active:before {
  --size: 3rem;
  content: "";
  position: relative;
  z-index: 1;
  flex: 0 0 var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #fff;
}

.c-stepper__item.tab_non_active:before {
  --size: 3rem;
  content: "";
  position: relative;
  z-index: 1;
  flex: 0 0 var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: var(--primary-color);
  border: 1px solid #fff;
}

.c-stepper__item {
  position: relative;
  display: flex;
  gap: 1rem;
  padding-bottom: 2.5rem;
}

.c-stepper__item:not(:last-child):after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translateX(1.5rem);
  width: 2px;
  background-color: #e0e0e0;
}

.c-stepper {
  --size: 3rem;
  --spacing: 0.5rem;
}

.c-stepper .c-stepper__index.tab_active {
  position: absolute;
  left: 18px;
  top: 13px;
  z-index: 2;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  color: var(--primary-color);
}

.c-stepper .c-stepper__index.tab_non_active {
  position: absolute;
  left: 18px;
  top: 13px;
  z-index: 2;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  color: #fff;
}

.for-doctor.c-stepper
  .c-stepper__item:last-child
  .c-stepper__content
  .c-stepper__index {
  left: 13px;
  top: 14px;
}

.c-stepper .c-stepper__title.tab_non_active {
  margin-bottom: 0;
  margin-top: 10px;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  font-size: 20px;
}

.c-stepper .c-stepper__title.tab_active {
  margin-bottom: 0;
  margin-top: 8px;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  font-size: 26px;
}
