.doc-gen-info .doc-profile-img {
  width: 4.5rem;
  height: 4.5rem;
  object-fit: cover;
  border-radius: 50%;
}

.doc-gen-info img {
  max-width: 4.5rem;
}

.doc-gen-info .upload-image-body {
  background: var(--primary-color);
  margin-top: -4rem;
  margin-left: -26px;
  font-size: 23px;
  border-radius: 50%;
}

.doc-gen-info .doctor-gen-info-upload-btn {
  width: 40px;
  margin-left: -34px;
  margin-top: -4rem;
  height: 27px;
  z-index: 0;
  opacity: 0;
}

.doc-gen-info .img-loader {
  height: 4.5rem;
  display: flex;
  align-items: center;
}

.doc-img {
  width: 6rem;
  object-fit: scale-down;
  height: 6rem;
}

.view-icon {
  background: #f2f2f2;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-mail-body {
  max-height: calc(100vh - 207px);
  overflow: scroll;
}

.email-attached-file {
  padding: 0.3rem;
  width: 80px !important;
  height: 80px !important;
  object-fit: cover;
  background: transparent;
}

.chat-attached-file {
  padding: 0.3rem;
  width: 450px !important;
  height: auto !important;
  object-fit: cover;
  background: transparent;
}

.lab-result {
  padding: 0.3rem;
  object-fit: cover;
  background: transparent;
  width: 100px !important;
  height: 60px !important;
  border-radius: 10px;
  border: 1px solid #0000000f;
  object-fit: cover;
}

.preview-overlay {
  width: 80px !important;
  height: 80px !important;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 9999;
}

.preview-parent {
  position: relative !important;
  display: flex;
}

.ant-select-dropdown {
  z-index: 9999 !important;
}

.recipients-select .ant-select {
  color: #3b3f5c;
  line-height: 3.3;
  margin-bottom: 15px;
}

.recipients-select
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 0;
  border-bottom: 1px solid rgba(212, 212, 212, 1);
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.admin-dashboard-appt-scroll {
  height: calc(100% - 100px);
  overflow: auto;
  background-color: white !important;
  border-radius: 10px;
  overflow-x: hidden;
}

.truncated-appt-name {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appt-card-shadow {
  box-shadow: rgb(0 0 0 / 12%) 0px 5px 15px !important;
}

.admin-dashboard-past-appt-scroll {
  height: calc(100% - 114px);
  background-color: white;
  overflow-y: auto;
  border-radius: 0.5rem;
  overflow-x: hidden;
}

.dashboard-custom-font-size {
  font-size: 1.8rem;
}

.recipients-select .ant-select-multiple .ant-select-selector {
  padding: 7px 4px;
}

.recipients-select
  .ant-select-multiple
  .ant-select-selection-item-remove
  > .anticon {
  vertical-align: 0.2em;
}

.recipients-select .ant-select-clear {
  right: 12px;
  margin-top: -12px;
  font-size: 16px;
}

.recipients-select
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: var(--error-or-danger-color) !important;
  border-bottom: 1px solid rgba(212, 212, 212, 1);
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #e1f5e673 !important;
}

.doctor-dashboard-status {
  background-color: #48b2ff;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
}

.attach-btn {
  margin-right: 0.7rem !important;
}

.email-attached-file-pdf {
  padding: 0.3rem;
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  background: transparent;
}

.email-attached-file-pdf iframe {
  overflow: hidden;
}

.received-user-mails-highlighted {
  background-color: #e1f5e673;
  padding: 5px 10px;
  border-radius: 18px;
  font-weight: 600;
  margin-right: 10px;
}

.new-message-modal .ant-upload-picture-card-wrapper {
  display: block !important;
}

.react-calendar__tile:disabled abbr {
  /* background: #f0f0f0 !important; */
  border-radius: 50% !important;
  padding: 10px !important;
  width: 10px !important;
  cursor: no-drop !important;
}

.appt-calendar .react-calendar__navigation button:disabled {
  background-color: #066860;
}
.appt-calendar .react-calendar__tile:disabled {
  background: none !important;
}


.fc .fc-cell-shaded, .fc .fc-day-disabled{
  background: #fff !important;
  cursor: no-drop !important;
}


/* .bottom-pagination .dropdown-menu{
  transform: translate3d(8px, 110px, 0px)!important;
} */

.profile-img-lg{
  width: 175px;
  height: 175px;
  object-fit: cover;
}

/* .img-right-grid {
  display: grid !important;
  grid-template-columns: auto auto auto;
  gap: 10px;
  justify-content: end;
  width: auto;
} */

.cursor-move{
  cursor: move;
}