.admin-dash-tile {
  border: 3px solid #066860;
}

.generic-green-text,
.generic-green-text path {
  color: #066860 !important;
  stroke: #066860 !important;
}

.admin-dash-tile img {
  height: 125px;
}
.admin-dash-border-left {
  border-right: 1px solid #d5d5d5;
}

.table-border-radius-left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-border-radius-right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.react-calendar {
  border: none !important;
  box-shadow: none !important;
}

.doctor-details-appt-card {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  padding: 0.75rem;
}

.create-role-btn,
.create-role-btn:hover,
.create-role-btn:active,
.create-role-btn:focus {
  background: white;
  border: 2px solid #066860;
  color: #066860;
  font-size: 32px;
  padding: 10px 20px;
  border-radius: 10px;
}

.create-employee-btn,
.create-employee-btn:hover,
.create-employee-btn:focus {
  background: #066860;
  border: 2px solid #066860;
  color: #fff;
  font-size: 20px;
  padding: 8px 30px;
  border-radius: 5px;
}

.create-employee-select:focus,
.create-employee-select:active {
  box-shadow: 0 0 5px 2px rgb(194 213 255 / 62%);
}

.create-newrole-select:focus,
.create-newrole-select:active {
  box-shadow: 0 0 5px 2px rgb(194 213 255 / 62%);
  border: none !important;
}

.create-newrole-select {
  border: none !important;
  border-radius: 5px;
  color: rgba(157, 157, 157, 1);
}

.create-role-save-btn,
.create-role-save-btn:hover,
.create-role-save-btn:active,
.create-role-save-btn:focus {
  background-color: #faae68 !important;
  border: 1px solid #faae68 !important;
  color: white;
  font-size: 25px;
  padding: 3px 40px;
  border-radius: 10px;
}

.admin-role-permission-tab .nav-link.active {
  background-color: var(--primary-color) !important;
  border-radius: 10px 10px 0px 0px !important;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.admin-role-permission-tab-inner {
  border-radius: 0px 10px 10px 10px;
  height: 100vh;
  padding: 50px 180px 50px 180px;
}

@media (max-width: 767px) {
  .admin-role-permission-tab-inner {
    padding: 25px 50px 25px 50px;
  }
}

.create-employee-day-card {
  border: 1px solid #acacac;
}
.create-employee-day-card table thead {
  border: none;
}

.create-employee-day-card .form-check .form-check-input {
  float: none;
  margin-right: 10px;
}

.create-employee-day-card .form-group label,
label {
  color: #000 !important;
}

.time-input-class {
  background-color: #f1eeee;
  border-radius: 5px;
  line-height: 20px;
  padding: 3px;
  color: #6a6a6a;
}

.create-employee-day-card tbody input {
  width: min-content;
}

.search-field-payment {
  border: none !important;
  border-radius: 5px 0px 0px 5px !important;
  height: calc(1.65em + 1.4rem + 2px) !important;
}

.search-field-payment {
  border: none !important;
  border-radius: 5px 0px 0px 5px !important;
  padding: 0px 15px !important;
}

.search-field-payment-btn {
  border: 1px solid #066860;
  background-color: #066860;
  color: #fff;
  padding: 0px 10px;
  border-radius: 5px;
}

.all-visits-parent-class.paid-table table td,
.all-visits-parent-class.refund-table table td {
  height: 70px;
}

.timeperiod-new-text,
.refund-new-text {
  padding: 5px 10px 7px 10px;
  background: #e6e6e6;
  border-radius: 22px;
  border: 1px solid #000;
  color: #000;
}

.refund-new-text {
  background-color: #2d3748 !important;
  color: #fff !important;
  cursor: pointer;
}

.pay-bullet-new-text {
  padding: 5px 25px 7px 25px;
  background: #e6e6e6;
  border-radius: 22px;
  border: 1px solid #2d3748;
  color: #2d3748;
  transition: 0.3s all ease-in-out;
  cursor: default;
}

.pay-bullet-new-text:hover {
  background: #2d3748;
  color: #fff;
}

.refund-table-reason-width {
  max-width: 15ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.filter-btn-refund,
.filter-btn-refund:hover,
.filter-btn-refund:active,
.filter-btn-refund:focus {
  background-color: #e7e7e7 !important;
  border: 1px solid #e7e7e7 !important;
  color: black;
  min-width: 100px;
}

.filter-btn-refund input {
  width: 20px !important;
  height: 20px !important;
}
