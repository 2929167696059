@media screen and (max-width: 768px) {
  .logo-size-adjustment {
    width: 65%;
  }

  .upload-image-center {
    padding-left: 6rem !important;
  }

  .button-align {
    margin-left: 4rem;
  }

  .pointing-image-size {
    width: 100%;
    height: 400px;
    /* margin-top: 30px; */
  }

  .media-query-d-flex {
    display: inline-grid !important;
    width: 100%;
  }

  .doctor-inner-style {
    border: 2px solid var(--secondary-color);
    z-index: 2;
    position: absolute;
    bottom: 0;
    margin-left: -9rem;
    margin-bottom: 29rem;
    border-radius: 12px;
    width: 130px;
  }

  /* .w-19 {
    width: 100%;
    border: 3px solid var(--primary-color);
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 10px;
  } */

  .upload-blog-button,
  .upload-blog-button:hover,
  .upload-blog-button:active,
  .upload-blog-button:focus {
    width: 130.7px;
    height: 35.62px;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 1.5rem 0rem 0px 30px;
  }

  .lab-record-text {
    margin-top: 15px;
    margin-left: 8px;
    font-size: 7px;
    font-weight: 400;
  }

  .card-box-styling-on-hide {
    width: 66%;
    float: right;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 40px;
    margin-right: 0px;
  }

  .payment-info-detail-text {
    font-size: 14px;
    font-weight: 700;
  }

  .query-size {
    font-size: 12px;
  }

  .cost-text {
    font-size: 22px;
    font-weight: 700;
    padding: 10px 32px 10px 27px;
    background: var(--primary-color);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    color: white;
    margin-left: -2.2rem;
  }

  .payment-info-available {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
    color: white;
  }

  .per-visit-text {
    font-size: 20px;
    font-weight: 700;
  }

  .payment-frequency {
    font-size: 25px;
    font-weight: 700;
    padding: 0px 0px 0px 20px;
  }

  .payment-methods {
    font-size: 20px;
    font-weight: 500;
  }

  .topnav input[type="text"] {
    padding: 14px;
    margin-top: 8px;
    font-size: 17px;
    border: none;
    width: 85%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .digit-code-text {
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-color);
  }

  .media-style-forgot {
    padding-left: 67px;
  }

  .forget-text {
    font-size: 25.06px !important;
    font-weight: 700 !important;
    color: var(--primary-color);
    /* margin-right: 32px !important; */
  }

  .signature-text {
    font-size: 26px;
    font-weight: 300;
    margin-left: 2rem;
  }

  .payment-detail-stripe-btn-style {
    padding: 0.5rem 2.5rem;
    border-radius: 10.58px;
    background: #ffffff !important;
    margin-left: 1rem;
    /* margin-right: 22px; */
    border: none;
    /* float: right; */
    color: black;
  }

  .payment-detail-btn-style {
    padding: 0.5rem 4rem;
    border-radius: 10.58px;
    background: #ffffff !important;
    margin-left: 8rem;
    /* margin-right: 22px; */
    border: none;
    /* float: right; */
    color: black;
  }

  .media-query-size {
    width: 40%;
  }

  .container-spacing {
    margin-left: 18px !important;
    margin-right: 0px !important;
  }

  .something-great-text {
    font-size: 32px;
    font-weight: 400;
  }

  .media-query-input-field {
    margin-left: -20px;
    width: 97%;
  }

  .login-button {
    width: 118% !important;
    height: 55px;
    border-radius: 10px;
    background: var(--secondary-color);
    margin-left: -18px !important;
    border: none;
  }

  .already-text {
    font-size: 28.39px !important;
    font-weight: 400;
    line-height: 37.67px;
  }

  .media-Affordable-text {
    font-size: 45px !important;
    font-weight: 400;
  }

  .communities-text {
    font-size: 25px !important;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .first-col-padding {
    padding-left: 0rem !important;
  }

  .tickmark-text {
    margin-left: 7px !important;
    font-size: 15px !important;
    font-weight: 400;
  }

  .second-col-padding {
    padding-left: 0rem !important;
    /* margin-right: -24px; */
  }

  /* .forget-text {
    font-size: 48.06px !important;
    font-weight: 700 !important;
    color: var(--primary-color);
    margin-right: 32px !important;
  } */
  .sign-in-google-text {
    font-size: 14px;
    font-weight: 600;
  }

  .checkbox-margin-set {
    margin-left: 0px !important;
  }

  .login-header-logo {
    padding: 1rem 15px;
  }

  .sign-up-text {
    font-size: 45px;
    font-weight: 700;
    line-height: 94.17px;
    text-align: center;
    margin-left: 1rem;
  }

  .social-media-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .login-screen .a-side {
    padding: 0;
  }

  .row.container-spacing .col-md-6.col-sm-12.col-12.mx-auto {
    padding: 0;
  }

  .label-text {
    margin-top: 2px;
  }

  .checkbox-styling {
    width: 17px;
    height: 18px;
    margin-left: 15px;
    margin-right: 10px;
    background-color: var(--primary-color);
    border: 2px solid white !important;
  }

  .complete-profile-text {
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    margin-left: 1rem !important;
  }

  .doctor-information-container {
    background-color: var(--primary-color);
    height: 135vh;
    /* overflow: auto; */
  }

  .information-font {
    font-size: 30px;
    font-weight: 400;
    margin-top: 7rem !important;
    padding-left: 6rem !important;
  }

  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }

  .c-stepper .c-stepper__title.tab_active {
    margin-bottom: 0;
    margin-top: 8px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    font-size: 22px;
  }

  .c-stepper .c-stepper__title.tab_non_active {
    margin-bottom: 0;
    margin-top: 10px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    font-size: 16px;
  }
}

@media screen and (max-width: 425px) {
  .login-button {
    width: 98% !important;
    height: 55px;
    border-radius: 10px;
    background: var(--secondary-color);
    margin-left: -18px !important;
    border: none;
  }

  .blog-parent-class .create-slot-button,
  .blog-parent-class .create-slot-button:hover,
  .blog-parent-class .create-slot-button:active,
  .blog-parent-class .create-slot-button:focus {
    width: 82.7px !important;
    height: 35.62px !important;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 1rem 0rem 0px 20px !important;
    font-size: 8px;
  }

  .upload-image-style {
    margin: 2rem 0px 0px 8px !important;
    width: 84% !important;
  }

  .upload-blog-button,
  .upload-blog-button:hover,
  .upload-blog-button:active,
  .upload-blog-button:focus {
    width: 82.7px;
    height: 35.62px;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 0rem 0rem 0px 0px;
  }

  .blog-parent-class .upload-text {
    margin-top: 15px;
    margin-left: 0.5rem;
    font-size: 7px;
  }

  .chat-box {
    background: #ffffff;
    /* position: fixed; */
    right: 0px;
    bottom: 0px;
    width: 100%;
    max-width: 100vw;
    max-height: 768px;
    border-radius: 5px;
    box-shadow: 0 0 30px 0 rgb(82 63 105 / 20%);
  }

  .card-box-styling-on-hide {
    width: 100%;
    float: right;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 51rem;
    margin-right: 0px;
  }

  .chat-box-parent-class.chat-box {
    background: #e9eeee;
    /* position: fixed; */
    right: 30px;
    bottom: 0px;
    width: 100%;
    max-width: 100vw;
    max-height: 768px;
    border-radius: 5px;
    /* -webkit-box-shadow: 0 0 30px 0 rgb(82 63 105 / 20%); */
    /* box-shadow: 0 0 30px 0 rgb(82 63 105 / 20%); */
  }

  .topnav input[type="text"] {
    padding: 14px;
    margin-top: 8px;
    font-size: 17px;
    border: none;
    width: 88%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .topnav .search-container button {
    float: right;
    padding: 15px 10px;
    margin-top: 7px;
    background: #fff;
    color: rgba(190, 190, 190, 1);
    font-size: 17px;
    border: none;
    cursor: pointer;
    position: inherit;
    margin-right: 0px !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .container-spacing {
    margin-left: 52px !important;
    margin-right: 0px !important;
  }

  .checkbox-margin-set {
    margin-left: -40px !important;
  }

  .float-right-class {
    float: none;
  }

  .patient-doctor-parent-class .container-spacing {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .create-text {
    font-size: 37px;
    font-weight: 700;
    line-height: 75.17px;
  }

  .already-text {
    font-size: 29px !important;
    font-weight: 400;
    line-height: 20.67px;
  }

  .free-text {
    font-size: 30px;
    font-weight: 400;
    line-height: 0px;
  }

  .paid-n-unpaid-parent .patient-view-tabs.active {
    background: var(--primary-color);
    padding: 12px 3rem 12px 3rem;
    color: white;
    cursor: pointer;
  }

  .paid-n-unpaid-parent .patient-view-tabs {
    padding: 12px 3rem 12px 3rem;
    background-color: rgba(208, 220, 219, 1);
    color: white;
    cursor: pointer;
  }
}

@media screen and (max-width: 640px) {
  .login-button,
  .login-button:focus,
  .login-button:active,
  .login-button:hover {
    width: 98% !important;
    border-radius: 10px;
    background: var(--secondary-color);
    margin-left: 0px !important;
    border: none;
    padding: 0.93rem 2rem;
  }

  .OT_subscriber {
    border-radius: 12px !important;
    min-width: 480px !important;
    min-height: 580px !important;
  }
  .OT_publisher {
    margin-left: 16.5rem !important;
    border-radius: 12px !important;
    top: 18rem;
    width: 140px !important;
    height: 120px !important;
  }

  .blog-parent-class .create-slot-button,
  .blog-parent-class .create-slot-button:hover,
  .blog-parent-class .create-slot-button:active,
  .blog-parent-class .create-slot-button:focus {
    width: 130.7px !important;
    height: 35.62px !important;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 2rem 0rem 0px 20px !important;
    font-size: 9px;
  }

  .upload-blog-button,
  .upload-blog-button:hover,
  .upload-blog-button:active,
  .upload-blog-button:focus {
    width: 130.7px;
    height: 35.62px;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 0rem 0rem 0px 30px;
  }

  .blog-parent-class .upload-text {
    margin-top: 15px;
    margin-left: 1.5rem;
    font-size: 9px;
  }

  .doctor-inner-style {
    border: 2px solid var(--secondary-color);
    z-index: 2;
    position: absolute;
    bottom: 0;
    margin-left: -9rem;
    border-radius: 12px;
    width: 130px !important;
    bottom: auto !important;
    top: 10rem !important;
  }

  .container-spacing {
    margin-left: 17px !important;
    margin-right: -3px !important;
  }

  .media-384-style-checkbox {
    padding-left: 2rem !important;
  }

  .media-query-input-field {
    margin-left: 0px;
    width: 97%;
  }

  .already-text {
    font-size: 25.39px !important;
    font-weight: 400;
    line-height: 37.67px;
    margin-left: 0px !important;
  }

  .media-Affordable-text {
    font-size: 35px !important;
    font-weight: 400;
    margin-left: -16px !important;
  }

  .communities-text {
    font-size: 20px !important;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-left: -17px;
  }
}

@media screen and (max-width: 480px) {
  .already-text {
    font-size: 20.39px !important;
    font-weight: 400;
    line-height: 1.67px;
  }

  .create-text {
    font-size: 37px;
    font-weight: 700;
    line-height: 75.17px;
  }

  .doctor-inner-style {
    border: 2px solid var(--secondary-color);
    z-index: 2;
    position: absolute;
    bottom: 0;
    margin-left: -4rem;
    border-radius: 12px;
    width: 56px !important;
    bottom: auto !important;
    top: 10rem !important;
  }

  .free-text {
    font-size: 30px;
    font-weight: 400;
    line-height: 0px;
  }

  .media-Affordable-text {
    font-size: 27px !important;
    font-weight: 400;
    margin-left: -16px !important;
  }

  .Start-for-free-text {
    margin-top: 0.8rem;
    padding-left: 18px !important;
  }

  .media-384-style-checkbox {
    padding-left: 4.5rem !important;
  }

  .container-spacing {
    margin-left: 27px !important;
    margin-right: 12px !important;
  }

  .something-great-text {
    font-size: 31px;
    font-weight: 400;
  }

  .login-header-logo {
    padding: 1rem 12px;
  }

  .media-384-main-style {
    margin-top: 12px !important;
  }

  .font-22 {
    font-size: 15px !important;
  }

  .tickmark-text {
    margin-left: 7px !important;
    font-size: 12px !important;
    font-weight: 400;
  }

  .communities-text {
    font-size: 15px !important;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-left: -17px;
  }

  .doctor-parent-class-for-pills .rounded-pill-radius-div {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    padding: 0px 0px 0px 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 100%;
  }

  .upload-image-center {
    padding-left: 0rem !important;
  }

  .information-font {
    font-size: 20px;
    font-weight: 400;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-left: 0px !important;
  }

  .next-button:hover,
  .next-button:active,
  .next-button:focus,
  .next-button {
    padding: 0.2rem 2rem;
    border-radius: 10px;
    background: var(--primary-color) !important;
    margin-left: 0px;
    margin-right: 22px;
    border: none;
    float: right;
  }

  .media-style-small-profile {
    width: 15%;
  }

  .upload-image-body {
    background: var(--primary-color);
    margin-top: -3rem;
    margin-left: -15px;
    font-size: 14px;
    border-radius: 50%;
  }

  .margin-left-alignment {
    margin-left: 1rem;
    margin-top: 0px !important;
  }

  .doctor-information-container {
    height: 98vh;
  }

  .add-button:hover,
  .add-button:active,
  .add-button:focus,
  .add-button {
    width: 175px;
    height: 45px;
    border-radius: 10px;
    background: var(--secondary-color) !important;
    margin-left: 0px;
    margin-right: 22px;
    border: none;
    float: right;
  }

  .add-more-class {
    background: #ffffff4d;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-text-in-button {
    font-size: 15px;
    font-weight: 600;
    padding-right: 2rem;
  }

  .div-height-overflow-class {
    overflow-x: auto;
    min-height: 75.3vh;
    height: 315px;
    margin-top: 1rem;
  }

  .button-align {
    margin-left: 1rem;
  }

  .signature-text {
    font-size: 26px;
    font-weight: 300;
    padding: 24px;
    margin-left: 0px;
  }

  .payment-detail-stripe-btn-style {
    padding: 0.5rem 1rem;
    border-radius: 10.58px;
    background: #ffffff !important;
    margin-left: 1rem;
    /* margin-right: 22px; */
    border: none;
    /* float: right; */
    color: black;
  }

  .payment-detail-btn-style {
    padding: 0.2rem 4rem;
    border-radius: 10.58px;
    background: #ffffff !important;
    margin-left: 1rem;
    /* margin-right: 22px; */
    border: none;
    /* float: right; */
    color: black;
  }

  .blog-parent-class .create-slot-button,
  .blog-parent-class .create-slot-button:hover,
  .blog-parent-class .create-slot-button:active,
  .blog-parent-class .create-slot-button:focus {
    width: 120.7px !important;
    height: 30.62px !important;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 2rem 0rem 0px 20px !important;
    font-size: 9px;
  }

  .upload-blog-button,
  .upload-blog-button:hover,
  .upload-blog-button:active,
  .upload-blog-button:focus {
    width: 120.7px;
    height: 30.62px;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 0rem 0rem 0px 30px;
  }

  .blog-parent-class .upload-text {
    margin-top: 15px;
    margin-left: 1.5rem;
    font-size: 9px;
  }
}

@media screen and (max-width: 384px) {
  .already-text {
    font-size: 13.39px !important;
    font-weight: 400;
    line-height: 0px;
  }

  .blog-parent-class .upload-text {
    margin-top: 15px;
    margin-left: 0.5rem;
    font-size: 8px;
  }

  .upload-blog-button,
  .upload-blog-button:hover,
  .upload-blog-button:active,
  .upload-blog-button:focus {
    width: 98.7px;
    height: 25.62px;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 0rem 0rem 0px 26px;
  }

  .blog-parent-class .create-slot-button,
  .blog-parent-class .create-slot-button:hover,
  .blog-parent-class .create-slot-button:active,
  .blog-parent-class .create-slot-button:focus {
    width: 98.7px !important;
    height: 25.62px !important;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 2rem 0rem 0px 20px !important;
    font-size: 8px;
  }

  .chat-box {
    background: #ffffff;
    /* position: fixed; */
    right: 0px;
    bottom: 0px;
    width: 100%;
    max-width: 100vw;
    max-height: 768px;
    border-radius: 5px;
    box-shadow: 0 0 30px 0 rgb(82 63 105 / 20%);
  }

  .chat-box-parent-class.chat-box {
    background: #e9eeee;
    /* position: fixed; */
    right: 30px;
    bottom: 0px;
    width: 100%;
    max-width: 100vw;
    max-height: 768px;
    border-radius: 5px;
    /* -webkit-box-shadow: 0 0 30px 0 rgb(82 63 105 / 20%); */
    /* box-shadow: 0 0 30px 0 rgb(82 63 105 / 20%); */
  }

  .topnav input[type="text"] {
    padding: 14px;
    margin-top: 8px;
    font-size: 17px;
    border: none;
    width: 88%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .topnav .search-container button {
    float: right;
    padding: 15px 10px;
    margin-top: 7px;
    background: #fff;
    color: rgba(190, 190, 190, 1);
    font-size: 17px;
    border: none;
    cursor: pointer;
    position: inherit;
    margin-right: 0px !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .card-box-styling-on-hide {
    width: 100%;
    float: right;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 51rem;
    margin-right: 0px;
  }

  .media-Affordable-text {
    font-size: 16px !important;
    font-weight: 400;
    margin-left: -13px !important;
    margin-bottom: 6px;
  }

  .communities-text {
    font-size: 8px !important;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-left: -13px;
  }

  .media-384-style-checkbox {
    padding-left: 4.5rem !important;
  }

  .forgot-text-color {
    color: lightgray !important;
    margin-right: -2rem;
  }

  .tickmark-text {
    margin-left: 7px !important;
    font-size: 10px !important;
    font-weight: 400;
  }

  .information-font {
    font-size: 17px;
    font-weight: 400;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .doctor-information-container {
    height: 98vh;
  }

  .media-384-main-style {
    margin-top: 1rem !important;
  }

  .something-great-text {
    font-size: 20px;
    font-weight: 400;
  }

  .media-style-small-profile {
    width: 15%;
  }

  .upload-image-body {
    background: var(--primary-color);
    margin-top: -3rem;
    margin-left: -15px;
    font-size: 14px;
    border-radius: 50%;
  }

  .margin-left-alignment {
    margin-left: 1rem;
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 1280px) {
  .upload-image-center {
    padding-left: 11rem;
  }
  

  .upload-image-style {
    margin: 3.5rem 0px 0px 13px !important;
    width: 85% !important;
  }

  .upload-blog-size {
    height: 153px;
  }

  .write-blog-image-size {
    height: 200px;
  }

  .create-blog-btn-styling,
  .create-blog-btn-styling:hover,
  .create-blog-btn-styling:active,
  .create-blog-btn-styling:focus {
    font-size: 12px;
  }

  .upload-blog-text-in-btn {
    font-size: 12px;
  }

  .dashboard-text-card {
    font-size: 13px;
    font-weight: 500;
  }

  .start-end-time-style-booked {
    padding: 15px 40px;
    border-radius: 12px;
    background-color: #80808029;
    cursor: no-drop;
  }

  .feedback-styling-name {
    background: var(--primary-color);
    color: white;
    border-radius: 4rem;
    padding: 15px;
  }

  .admin-feedback-profile-image > img {
    height: 60px !important;
    width: 60px !important;
    margin-top: 13px;
    margin-bottom: 15px;
  }

  .admin-feedback-parent .patient-view-name {
    font-size: 12px;
    font-weight: 700;
    margin-left: 5px;
  }

  .community-forum-parent-class .patient-view-profession {
    font-size: 8px;
    font-weight: 400;
    margin-left: 15px;
    color: rgba(102, 102, 102, 1);
  }

  .admin-feedback-parent .patient-view-profession {
    margin-left: 5px;
  }

  .admin-feedback-profile-image .patient-view-profession {
    color: #fff;
  }

  .start-end-time-style-available {
    padding: 15px 40px;
    border-radius: 12px;
    /* background-color: rgba(232, 246, 255, 1); */
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    cursor: pointer;
  }

  .dashboard-card-sub-text {
    font-size: 11px;
    font-weight: 500;
    color: rgba(179, 179, 179, 1);
  }

  .lab-record-text {
    margin-top: 15px;
    margin-left: 8px;
    font-size: 11px;
    font-weight: 400;
  }

  .dashboard-upcomming {
    font-size: 18px;
    font-weight: 500;
  }

  .chart-height {
    height: 299px;
  }

  .dashboard-images-size {
    width: 30px;
  }

  .doctor-inner-style {
    border: 2px solid var(--secondary-color);
    z-index: 2;
    position: absolute;
    bottom: 0;
    margin-left: -15rem;
    margin-bottom: 19rem;
    border-radius: 12px;
    width: 200px;
  }

  .cost-text {
    font-size: 27px;
    font-weight: 700;
    padding: 10px 26px 10px 20px;
    background: var(--primary-color);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    color: white;
    margin-left: -1.2rem;
  }

  .blog-parent-class .upload-text {
    margin-top: 15px;
    margin-left: 2rem;
    font-size: 11px;
  }

  .upload-blog-button,
  .upload-blog-button:hover,
  .upload-blog-button:active,
  .upload-blog-button:focus {
    width: 160.7px;
    height: 45.62px;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 1rem 0rem 0px 30px;
  }

  .blog-parent-class .create-slot-button,
  .blog-parent-class .create-slot-button:hover,
  .blog-parent-class .create-slot-button:active,
  .blog-parent-class .create-slot-button:focus {
    width: 160.7px !important;
    height: 45.62px !important;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    font-size: 11px;
    margin: 2rem 1rem 0px 20px !important;
  }

  .slots-for-booked {
    background-color: rgba(225, 245, 230, 1);
    border-radius: 50%;
    border: 6px solid #fffcfcad;
    padding: 10px 9px 0px 18px;
    margin-left: 10px;
  }

  .media-class-small {
    font-size: 9px;
  }

  .slots-for-cancelled {
    background-color: rgba(255, 213, 213, 1);
    border-radius: 50%;
    border: 6px solid #fffcfcad;
    padding: 10px 9px 0px 18px;
    margin-left: 10px;
  }

  .slots-for-available {
    background-color: rgba(232, 246, 255, 1);
    border-radius: 50%;
    border: 6px solid #fffcfcad;
    padding: 10px 9px 0px 18px;
    margin-left: 10px;
  }

  .tabs-name-text {
    font-size: 10px;
    font-weight: 700;
    /* background: #eaeaea; */
    border-radius: 5px;
    /* color: #989898; */
  }

  .per-visit-text {
    font-size: 25px;
    font-weight: 700;
  }

  .payment-frequency {
    font-size: 25px;
    font-weight: 700;
    padding: 0px 0px 0px 20px;
  }

  .payment-methods {
    font-size: 20px;
    font-weight: 500;
  }

  .payment-info-available {
    font-size: 21px;
    font-weight: 400;
    margin-bottom: 0;
    color: white;
  }

  .payment-info-cash-text {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 0;
    color: white;
  }

  .payment-info-detail-text {
    font-size: 18px;
    font-weight: 700;
  }

  .background-image-with-color-card-section {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 85%;
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: center;
    padding: 5rem 20px;
  }

  .topnav input[type="text"] {
    padding: 14px;
    margin-top: 8px;
    font-size: 17px;
    border: none;
    width: 88%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

@media screen and (max-width: 1097.41px) {
  .upload-image-center {
    padding-left: 11rem;
  }


  .tabs-name-text {
    font-size: 8px;
    font-weight: 700;
    /* background: #eaeaea; */
    border-radius: 5px;
    /* color: #989898; */
    margin-left: -14px;
  }

  .feedback-styling-name {
    background: var(--primary-color);
    color: white;
    border-radius: 4rem;
    padding: 10px;
  }

  .admin-feedback-profile-image > img {
    height: 50px !important;
    width: 50px !important;
    margin-top: 13px;
    margin-bottom: 15px;
    margin-left: -11px;
  }

  .admin-feedback-parent .patient-view-name {
    font-size: 10px;
    font-weight: 700;
    margin-left: 3px;
  }

  .community-forum-parent-class .patient-view-profession {
    font-size: 8px;
    font-weight: 400;
    margin-left: 15px;
    color: rgba(102, 102, 102, 1);
  }

  .admin-feedback-parent .patient-view-profession {
    margin-left: 5px;
  }

  .admin-feedback-profile-image .patient-view-profession {
    color: #fff;
  }

  h2 {
    font-size: 20px !important;
  }

  .media-qyery-svg-style {
    font-size: 10px !important;
  }

  .start-end-time-style-booked {
    padding: 15px 15px;
    border-radius: 12px;
    background-color: #80808029;
    cursor: no-drop;
  }

  .start-end-time-style-available {
    padding: 15px 15px;
    border-radius: 12px;
    /* background-color: rgba(232, 246, 255, 1); */
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    cursor: pointer;
  }

  .font-size-style {
    font-size: 11px;
  }

  .app-sec-border {
    border-right: 3.28648px solid #066860;
    padding-right: 0.5rem;
  }

  .appointment-info .title-det {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 26px;
    color: #2d3748;
    margin-bottom: 1rem;
    text-align: center;
  }

  .appointment-info .sub-title-det {
    font-family: "Lato";
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 26px;
    color: #2d3748;
  }

  .appointment-info .sub-title-status {
    background: #a8ffcb;
    padding: 5px 8px;
    border-radius: 5.47746px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 26px;
    color: #299453;
  }

  .appointment-info .sub-title-det-icon {
    font-family: "Lato";
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #2d3748;
  }

  .lab-record-text {
    margin-top: 15px;
    margin-left: 1px;
    font-size: 9px;
    font-weight: 400;
  }

  .subscriber-parent-class-body .profile-image > img {
    height: 35px !important;
    width: 35px !important;
  }

  .patient-view-email {
    font-size: 13px;
    font-weight: 400;
    margin-left: 15px;
  }

  .patient-view-name {
    font-size: 20px;
    font-weight: 500;
    margin-left: 15px;
  }

  .dashboard-card-sub-text {
    font-size: 9px;
    font-weight: 500;
    color: rgba(179, 179, 179, 1);
  }

  .dashboard-text-card {
    font-size: 11px;
    font-weight: 500;
  }

  .dashboard-text-card-community {
    font-size: 9px;
    font-weight: 500;
  }

  .subscriber-parent-class-body .patient-view-name {
    font-size: 11px;
    font-weight: 500;
    margin-left: 15px;
  }

  .doctor-inner-style {
    border: 2px solid var(--secondary-color);
    z-index: 2;
    position: absolute;
    bottom: 0;
    margin-left: -12rem;
    margin-bottom: 22rem;
    border-radius: 12px;
    width: 166px;
  }

  .upload-image-body {
    background: var(--primary-color);
    margin-top: -4rem;
    margin-left: -20px;
    font-size: 18px;
    border-radius: 50%;
  }

  .payment-info-detail-text {
    font-size: 12px;
    font-weight: 700;
  }

  /* .blog-parent-class .create-slot-button,
  .blog-parent-class .create-slot-button:hover,
  .blog-parent-class .create-slot-button:active,
  .blog-parent-class .create-slot-button:focus {
    width: 160.7px !important;
    height: 45.62px !important;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 2rem 0rem 0px 20px !important;
    font-size: 12px;
  } */
  .blog-parent-class .create-slot-button,
  .blog-parent-class .create-slot-button:hover,
  .blog-parent-class .create-slot-button:active,
  .blog-parent-class .create-slot-button:focus {
    width: 130.7px !important;
    height: 35.62px !important;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 2rem 1rem 0px 20px !important;
    font-size: 9px;
  }

  .upload-blog-button,
  .upload-blog-button:hover,
  .upload-blog-button:active,
  .upload-blog-button:focus {
    width: 130.7px;
    height: 35.62px;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 1.5rem 0rem 0px 19px !important;
  }

  .blog-parent-class .upload-text {
    margin-top: 15px;
    margin-left: 2rem;
    font-size: 9px;
  }

  .upload-image-style {
    margin: 3rem 0px 0px 8px !important;
    width: 84% !important;
  }

  .cost-text {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 32px 10px 13px;
    background: var(--primary-color);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    color: white;
    margin-left: -1.2rem;
  }

  .per-visit-text {
    font-size: 22px;
    font-weight: 700;
  }

  .payment-frequency {
    font-size: 25px;
    font-weight: 700;
    padding: 0px 0px 0px 20px;
  }

  .payment-methods {
    font-size: 17px;
    font-weight: 500;
  }

  .payment-info-available {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 0;
    color: white;
  }

  .payment-info-cash-text {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 0;
    color: white;
  }

  .query-size {
    font-size: 9px;
  }

  .topnav input[type="text"] {
    padding: 14px;
    margin-top: 8px;
    font-size: 17px;
    border: none;
    width: 83%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

@media screen and (max-width: 960px) {
  .upload-image-center {
    padding-left: 9rem;
  }

  .app-sec-border {
    border-right: 3.28648px solid #066860;
    padding-right: 5.5rem;
  }

  .query-class-for-display {
    display: grid;
  }

  .w-19 {
    width: 100% !important;
    border: 3px solid var(--primary-color);
    padding: 10px;
    border-radius: 12px;
    /* margin-right: 21px; */
    margin-bottom: 10px;
  }

  .doctor-inner-style {
    border: 2px solid var(--secondary-color);
    z-index: 2;
    position: absolute;
    bottom: 0;
    margin-left: -10rem;
    margin-bottom: 29rem;
    border-radius: 12px;
    width: 151px;
  }

  .payment-detail-btn-style {
    padding: 0.7rem 3.5rem;
    border-radius: 10.58px;
    background: #ffffff !important;
    margin-left: 8rem;
    /* margin-right: 22px; */
    border: none;
    /* float: right; */
    color: black;
  }

  .payment-detail-stripe-btn-style {
    padding: 0.7rem 2rem;
    border-radius: 10.58px;
    background: #ffffff !important;
    margin-left: 1rem;
    /* margin-right: 22px; */
    border: none;
    /* float: right; */
    color: black;
  }

  .query-heigh-size {
    height: 35px;
  }
}

@media screen and (max-width: 1745.44px) {
  .background-image-with-color-card-section {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 78%;
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: center;
    padding: 5rem 20px;
  }

  .upload-image-style {
    margin: 4rem 0px 0px 20px;
    width: 82% !important;
  }

  .class-for-span-section {
    margin-top: 14px;
    margin-left: 1rem;
  }

  .write-blog-image-size {
    width: 68%;
  }

  .upload-image-style {
    margin: 4rem 0px 0px 20px;
    width: 85% !important;
  }

  .blog-parent-class .create-slot-button,
  .blog-parent-class .create-slot-button:hover,
  .blog-parent-class .create-slot-button:active,
  .blog-parent-class .create-slot-button:focus {
    width: 258.7px;
    height: 50.62px;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 2rem 0rem 0px 20px;
  }
}

@media screen and (min-width: 1920px) {
  .blog-parent-class .create-slot-button,
  .blog-parent-class .create-slot-button:hover,
  .blog-parent-class .create-slot-button:active,
  .blog-parent-class .create-slot-button:focus {
    width: 258.7px;
    height: 50.62px;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;

    margin: 2rem 3rem 0px 20px;
  }
  
}

@media screen and (max-width: 1536px) {
  .blog-parent-class .create-slot-button,
  .blog-parent-class .create-slot-button:hover,
  .blog-parent-class .create-slot-button:active,
  .blog-parent-class .create-slot-button:focus {
    width: 258.7px;
    height: 50.62px;
    border-radius: 12px;
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    margin: 2rem 0rem 0px 20px;
  }

  .visit-time-media-query-class {
    margin-right: -15px;
  }

  .visit-date-media-query-class {
    margin-left: -15px;
  }

  .upload-image-style {
    margin: 4rem 0px 0px 20px;
    width: 78% !important;
  }

  .patient-dashboard-card-text {
    margin-top: 7rem;
    margin-bottom: 3px;
    font-size: 13px;
    font-weight: 500;
  }
}


@media screen and (max-width: 576px) {
  .col-rev {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .justify-end {
    float: right;
  }

  .text-left > .view-notes-doc-specialist,
  .text-left .view-notes-doc-name {
    text-align: left;
  }

  .qr-size {
    width: 61px !important;
    margin-right: 2rem;
    height: 25px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1280px) {
  .OT_subscriber {
    border-radius: 12px !important;
    min-width: 570px !important;
    min-height: 580px !important;
  }
  .OT_publisher {
    margin-left: 20.5rem !important;
    border-radius: 12px !important;
    top: 17rem;
    width: 160px !important;
    height: 130px !important;
  }
}

@media screen and (min-width: 1097.14px) and (max-width: 1097.14px) {
  .OT_subscriber {
    border-radius: 12px !important;
    min-width: 570px !important;
    min-height: 580px !important;
  }
  .OT_publisher {
    margin-left: 20.5rem !important;
    border-radius: 12px !important;
    top: 17rem;
    width: 160px !important;
    height: 130px !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 960px) {
  .OT_subscriber {
    border-radius: 12px !important;
    min-width: 480px !important;
    min-height: 580px !important;
  }
  .OT_publisher {
    margin-left: 16.5rem !important;
    border-radius: 12px !important;
    top: 18rem;
    width: 140px !important;
    height: 120px !important;
  }
}

@media screen and (min-width: 1536px) and (max-width: 1536px) {
  .OT_publisher {
    position: absolute;
    margin-left: 27.5rem !important;
    border-radius: 12px !important;
    top: 22rem;
    width: 200px !important;
    height: 150px !important;
  }
  
  .OT_subscriber {
    border-radius: 12px !important;
    min-width: 720px !important;
    min-height: 680px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
  .OT_subscriber {
    border-radius: 12px !important;
    min-width: 480px !important;
    min-height: 580px !important;
  }
  .OT_publisher {
    margin-left: 16.5rem !important;
    border-radius: 12px !important;
    top: 18rem;
    width: 140px !important;
    height: 120px !important;
  }
}

@media screen and (min-width: 1745.45px) and (max-width: 1745.45px) {
  .OT_publisher {
    position: absolute;
    min-width: 48px;
    min-height: 48px;
    margin-left: 31.5rem !important;
    border-radius: 12px !important;
    top: 23rem;
    width: 250px !important;
    height: 200px !important;
  }

  .OT_subscriber {
    border-radius: 12px !important;
    min-width: 840px !important;
    min-height: 750px !important;
  }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
  .OT_subscriber {
    border-radius: 12px !important;
    min-width: 950px !important;
    min-height: 820px !important;
  }
}
